import "./src/styles/reset.css"
import "./src/styles/fonts.css"
import "./src/styles/footer.css"
import "./src/styles/locations.css"
import "./src/styles/homePage/homePage.css"
import "./src/styles/homePage/welcomeAnimation.css"
import "./src/styles/homePage/ourClients.css"
import "./src/styles/homePage/ourNews.css"
import "./src/styles/homePage/aboutUs.css"
import "./src/styles/homePage/homepageWork.css"
import "./src/styles/menu.css"
import "./src/styles/services/servicesHeader.css";
import "./src/styles/header.css";
import "./src/styles/services/servicesPages.css";
import "./src/styles/contactus.css";
import "./src/styles/services/services.css";
import "./src/styles/services/servicesBottomNav.css";
import "./src/styles/jobs.css"
import "./src/styles/work/work.css";
import "./src/styles/contactForm.css"
import "./src/styles/news.css"
import "./src/styles/about.css";
import "./src/styles/news/singleNews.css"
import "./src/styles/terms.css"
import "./src/styles/cookieBar.css"
import "./src/styles/services/drupal.css";
import "./src/styles/work/portfolio-items.css";